import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Card, Grid, CardContent, Divider } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { useEffect } from 'react';
import { getOrders, getProducts, getStore, getWarehouses } from '../../app/store/actions/imsAdminActions';
import ImageComponent from '../../app/components/ImageComponent';
import { AccountCircle, Inventory, People, Receipt, Warehouse } from '@mui/icons-material';
import OrdersTable from '../../app/components/OrdersTable';
import InboundsTable from '../../app/components/InboundsTable';

export const IMSAdminHome = ({ getOrders, orders, getProducts, products }) => {

    const navigate = useNavigate()
    const eventId = useParams().id

    useEffect(() => {
        getOrders({page: 1, limit: 10, inside: true})
        getProducts({page: 1, limit: 10, hasInbounds: true})
    }, [])

    return (
        <div>
            <Box sx={{ ...centered_flex_box, flexDirection: "row" }}>
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/admin/orders")}><Receipt /><Typography marginX={2}>Orders</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/admin/products")}><Inventory /><Typography marginX={2}>Products</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/admin/warehouses")}><Warehouse /><Typography marginX={2}>Warehouses</Typography></Button>}
                {<Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/admin/employees")}><People /><Typography marginX={2}>Employees</Typography></Button>}
            </Box>
            <Divider/>
            <Box  sx={{ ...centered_flex_box, flexDirection: "column", mt: 10, width: "100%" }}>
                <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Pending Inbounds <Button sx={{ ...main_button, m: 1 }} onClick={() => navigate("/ims/admin/inbounds")}>View All</Button></Typography>
                <InboundsTable products={products?.docs ? [] : products} />
            </Box>
            <Box  sx={{ ...centered_flex_box, flexDirection: "column", mt: 10, width: "100%" }}>
                <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Orders with CHAINZ</Typography>
                <OrdersTable orders={orders?.docs} />
            </Box>
        </div>
    );
}

const mapStateToProps = (state) => ({
    store: state?.records?.store,
    warehouses: state?.records?.warehouses,
    orders: state?.records?.orders,
    products: state?.records?.products
});

const mapDispatchToProps = { getOrders, getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(IMSAdminHome);