import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Button, Image, Space, Table, Tag } from "antd";
import moment from "moment";

export const OrdersTable = ({ orders, isLoading }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Date Ordered',
      dataIndex: 'date',
      sorter: (a, b) => {return moment(a.date).isBefore(b.date) ? -1 : 1},
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => {return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1},
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => {return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1},
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer',
      filters: orders?.filter((order) => order.orderFulfillment !== null).map((order) => {return {
        text: order.orderFulfillment?.customer,
        value: order.orderFulfillment?.customer
      }}),
      filterSearch: true,
      onFilter: (value, record) => record.customer.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Ready for Pickup",
          value: "Ready for Pickup"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, {status}) => <Tag color={status === "Delivered" ? "green" : status === "Cancelled" ? "red" : status === "Prepared" ? "blue" : "yellow"}>{status.toUpperCase()}</Tag>
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      filters: orders?.filter((order) => order.vendor !== null).map((order) => {return {
        text: order.vendor?.name,
        value: order.vendor?.name
      }}),
      filterSearch: true,
      onFilter: (value, record) => record.vendor.name.startsWith(value),
    },
    {
      title: 'Actions',
      render: (_, {_id}) => <Space>
        <Button onClick={() => navigate(`/ims/admin/order/${_id}`)}>View Order</Button>
      </Space>
    },
  ];

  const data = orders?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.preparedAt ? moment(row.preparedAt).format("lll") : null,
      dateDelivered: row.deliveredAt ? moment(row.deliveredAt).format("lll") : null,
      status: row.status,
      customer: row.orderFulfillment.customer,
      vendor: row.vendor.name,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
