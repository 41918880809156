import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { AddOrder, AddProducts, CreateEmployee, CreateWarehouse, EmployeesList, Home, IMSAdminHome, IMSEmployeeHome, IMSEmployeeOrders, InboundsList, LoginIMSAdmin, LoginIMSEmployee, Order, OrdersList, PackOrder, PackingSystem, ProductsList, ReceiveInbounds, RegisterIMS, Warehouse, WarehousesList } from "./Pages";
import ProtectedRoute from "./ProtectedRoute";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/register" exact element={<RegisterIMS />} />
      <Route path="/login" exact element={<LoginIMSAdmin />} />
      <Route exact element={<ProtectedRoute allowed={["InventoryAdmin"]} />}>
        <Route path="/ims/admin" exact element={<IMSAdminHome />} />
        <Route path="/ims/admin/products" exact element={<ProductsList />} />
        <Route path="/ims/admin/products/add" exact element={<AddProducts />} />
        <Route path="/ims/admin/inbounds" exact element={<InboundsList />} />
        <Route path="/ims/admin/inbounds/receive/:warehouseId" exact element={<ReceiveInbounds />} />
        <Route path="/ims/admin/warehouses" exact element={<WarehousesList />} />
        <Route path="/ims/admin/warehouse/:warehouseId" exact element={<Warehouse />} />
        <Route path="/ims/admin/warehouses/create" exact element={<CreateWarehouse />} />
        <Route path="/ims/admin/orders" exact element={<OrdersList />} />
        <Route path="/ims/admin/order/:orderId" exact element={<Order />} />
        <Route path="/ims/admin/orders/add" exact element={<AddOrder />} />
        <Route path="/ims/admin/employees" exact element={<EmployeesList />} />
        <Route path="/ims/admin/employees/create" exact element={<CreateEmployee />} />
        <Route path="/ims/admin/packing" exact element={<PackingSystem />} />
      </Route>
      <Route path="/ims/employee/login" exact element={<LoginIMSEmployee />} />
      <Route exact element={<ProtectedRoute allowed={["InventoryEmployee"]} />}>
        <Route path="/ims/employee" exact element={<IMSEmployeeHome />} />
        <Route path="/ims/employee/orders" exact element={<IMSEmployeeOrders />} />
        <Route path="/ims/employee/order/:orderId" exact element={<PackOrder />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
