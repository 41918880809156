import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import { loginIMSAdmin, signUp } from "../../app/store/actions/authActions";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  left_flex_box
} from "../../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table, notification } from "antd";
import Logo from "../../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ImageComponent from "../../app/components/ImageComponent";
import { getProducts, getWarehouses, receiveInbound } from "../../app/store/actions/imsAdminActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment/moment";
import InboundsTable from "../../app/components/InboundsTable";
import { useState } from "react";
import mongoose from "mongoose";
import { getRequest } from "../../core/network";

export const ReceiveInbounds = ({
  getProducts,
  getWarehouses,
  receiveInbound,
  user,
  token,
  warehouses,
  products,
  isLoading
}) => {
  const navigate = useNavigate();
  const { warehouseId } = useParams();
  const [inboundItemId, setInboundItemId] = useState(null);
  const [inboundItems, setInboundItems] = useState({});
  const [gettingItem, setGettingItem] = useState(false);

  useEffect(() => {
    getWarehouses({ warehouseId })
  }, [warehouseId])

  useEffect(() => {
    if (mongoose.Types.ObjectId.isValid(inboundItemId)) {
      handleGetInboundItem()
    }
  }, [inboundItemId])

  const handleGetInboundItem = () => {
    setGettingItem(true)
    getRequest({ inboundItemId, warehouseId }, undefined, token, "IMAdmin/inbounds/items").then(({ data }) => {
      const { _id, product } = data;
      let temp = inboundItems
      if (Object.keys(temp).includes(_id)) {
        temp[_id].quantityReceived = temp[_id].quantityReceived + 1
      } else {
        temp[_id] = { quantityReceived: 1, _id, product }
      }
      setInboundItems(temp)
    }).catch((err) => { console.error(err); notification.error(err.data.message) }).finally(() => {
      setGettingItem(false);
      setInboundItemId("");
    })
    console.log(inboundItems)
  }

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5, mt: 2 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor)" }}>Receive Inbound Items</Typography>
          {/* <IconButton onClick={() => navigate("/ims/admin/orders/add")} sx={{ color: "var(--secColor)", mx: 2 }}><AddCircleOutline /></IconButton> */}
        </Box>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Box sx={{ ...left_flex_box }}>
                <MainInput
                  sx={{ marginInlineEnd: 2, minWidth: "50vw" }}
                  focused
                  value={inboundItemId}
                  label="Item Id"
                  onChange={({ target }) => setInboundItemId((target.value))}
                />
                <Button onClick={() => mongoose.Types.ObjectId.isValid(inboundItemId) ? handleGetInboundItem() : null} disabled={gettingItem} sx={{ ...main_button }}>{gettingItem ? <CircularProgress color="white" /> : "Scan Item"}</Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box>
          {Object.values(inboundItems)?.map((item) => {
            return (
              <Card key={item._id}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={2}>
                      <Avatar src={item.product.image} />
                    </Grid>
                    <Grid item xs={8} sx={{ ...centered_flex_box }}>
                      <Typography>{item.product.name}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ ...centered_flex_box }}>
                      <Typography>{item.quantityReceived}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })}
        </Box>
        <Box sx={{ ...centered_flex_box, mt: 3 }}>
          <Button onClick={() => receiveInbound({ itemUpdates: Object.values(inboundItems) }, navigate)} disabled={isLoading} sx={{ ...main_button }}>{isLoading ? <CircularProgress color="white" /> : "Receive All"}</Button>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  products: state?.records?.products,
  warehouses: state?.records?.warehouses?.docs
});

const mapDispatchToProps = { getProducts, getWarehouses, receiveInbound };

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveInbounds);
